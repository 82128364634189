import * as React from 'react';
import { IPropertyDTO } from '../models/IProperty';
import { FaGlobe, FaPhone, FaRegEnvelope } from 'react-icons/fa6'

interface IProps {
    group: IPropertyDTO | null
}
export function DisplayGroup(p: IProps) {

    if (p.group == null) return <>loading ...</>

    return (
        <div className='gdcontent border-b border-gray-200 bg-white px-4 py-5 sm:px-6 rounded-lg shadow'>
            <div className='introduction mb-3'>
                <p className='text-lg font-bold'>{p.group.Name}</p>
                <div className='text' dangerouslySetInnerHTML={{ __html: p.group.Introduction }} ></div>
            </div>

            <div className='flex flex-row content-start items-center gap-3'>
                <a href={'tel:' + p.group.PhoneNumber}>
                    <div className='phonenumber flex items-center gap-1'>
                        <div><FaPhone /></div>
                        <div>{p.group.PhoneNumber}</div>
                    </div>
                </a>

                <a href={'mailto:' + p.group.MailAddress}>

                    <div className='e-mail flex items-center gap-1'>
                        <div><FaRegEnvelope /></div>
                        <div>{p.group.MailAddress}</div>
                    </div>
                </a>

                <a href={p.group.WebsiteLink} target='_blank' rel='noreferrer'>
                    <div className='website flex items-center gap-1'>
                        <FaGlobe />
                        <div>{p.group.WebsiteLink}</div>
                    </div>
                </a>
            </div>
        </div>

    )
}